import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Col, Form } from 'reactstrap';
import OdontogramSvg from './OdontogramSvg';

const Odontogram = (props) => {
    const [clicked, setClicked]=useState(false)
    
    const initialTeethState = [
        { fdi_name: '11', teeth_state_id: 1 },
        { fdi_name: '12', teeth_state_id: 1 },
        { fdi_name: '13', teeth_state_id: 1 },
        { fdi_name: '14', teeth_state_id: 1 },
        { fdi_name: '15', teeth_state_id: 1 },
        { fdi_name: '16', teeth_state_id: 1 },
        { fdi_name: '17', teeth_state_id: 1 },
        { fdi_name: '18', teeth_state_id: 1 },
        { fdi_name: '21', teeth_state_id: 1 },
        { fdi_name: '22', teeth_state_id: 1 },
        { fdi_name: '23', teeth_state_id: 1 },
        { fdi_name: '24', teeth_state_id: 1 },
        { fdi_name: '25', teeth_state_id: 1 },
        { fdi_name: '26', teeth_state_id: 1 },
        { fdi_name: '27', teeth_state_id: 1 },
        { fdi_name: '28', teeth_state_id: 1 },
        { fdi_name: '31', teeth_state_id: 1 },
        { fdi_name: '32', teeth_state_id: 1 },
        { fdi_name: '33', teeth_state_id: 1 },
        { fdi_name: '34', teeth_state_id: 1 },
        { fdi_name: '35', teeth_state_id: 1 },
        { fdi_name: '36', teeth_state_id: 1 },
        { fdi_name: '37', teeth_state_id: 1 },
        { fdi_name: '38', teeth_state_id: 1 },
        { fdi_name: '41', teeth_state_id: 1 },
        { fdi_name: '42', teeth_state_id: 1 },
        { fdi_name: '43', teeth_state_id: 1 },
        { fdi_name: '44', teeth_state_id: 1 },
        { fdi_name: '45', teeth_state_id: 1 },
        { fdi_name: '46', teeth_state_id: 1 },
        { fdi_name: '47', teeth_state_id: 1 },
        { fdi_name: '48', teeth_state_id: 1 }
	]
    
    const [teethState, setTeethState] = useState(initialTeethState)
    const [matrix, setMatrix]= useState( { x: 0, y: 0, width: 0, height: 0 })
    const setColorId = (id) => color[teethState.find(teeth => teeth.fdi_name === id).teeth_state_id]
 
    const updateTeethStateChanged = (e) => {
        e.stopPropagation()
        setClicked(!clicked)
        const tooth = teethState.find(teeth => teeth.fdi_name === e.target.id)
        console.log(tooth)
        let counter
       
        counter=tooth.teeth_state_id

        if(counter === Object.keys(color).length)
        { counter = 1} else {
            counter++
        }
                
        let newTeethState = [ ...teethState ]
        
        const newTooth =newTeethState.find(teeth => teeth.fdi_name === e.target.id).teeth_state_id
        
        newTeethState.find(teeth => teeth.fdi_name === e.target.id).teeth_state_id = counter; 

        e.target.setAttribute('fill', color[tooth])

        const matrix = e.target.getBBox()
        
        setTeethState(newTeethState); 
        setMatrix(matrix) 
    }


    const color={
        1:'#222526', //ausente 
        2:'#F4F2E5', //completo
        3:'#E20000', //fracturado
        4:'#006666', //resto radicular
        5:'#878681' //implante
    }

    const saveData=()=>{
        props.updateOdontogram(props.odontogramId, teethState)
        props.setOpen(false)
        props.getTeethState(props.odontogramId)
        props.getOdontogramId()
    }


    return (
        <Modal
            className='custom-modal-style'
            isOpen={props.open}
            cssModule={{ 'modal-title': 'w-100 text-center' }}
            style={{ maxWidth: '60%', maxHeight: '50%' }}>
            <ModalHeader>
             Selector de piezas dentales
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <Col md="12">
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            id='svg8'
                            width="100%"
                            height="600"
                            version="1.1"
                            viewBox="0 0 125.857 200.777"  
                        >   
                            <OdontogramSvg updateTeethStateChanged={updateTeethStateChanged} setColorId={setColorId}/>
                        </svg>   
                    </Col>
                </div>
                <ModalFooter>
                    <Button  color="primary" onClick={() => saveData()}>
                        Guardar
                    </Button>
                    <Button color="secondary"
                        onClick={() => props.setOpen(false)}>Cancelar
                             </Button>
                </ModalFooter>
            </ModalBody>
                      
        </Modal>
    )
}

export default Odontogram;