import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Container, FormGroup, Input, Card, CardBody, Label, Table, Modal, ModalHeader, ModalBody, Form} from 'reactstrap';
import {getList} from 'helpers/ListHelper';
import SimpleModal from "../components/SimpleModal";
import axios from '../../../helpers/axiosConfig';

const ConfCapsules = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCapsules();
    }, []);

    /*CAPSULES##################################################################################*/ 
    const defaultCapsule = {capsule_id: 0, capsule_description: "", capsule_url: ""};
    const [capsules, setCapsules] = useState([]);
    const [capsule, setCapsule] = useState(defaultCapsule);
    const fetchCapsules = async () => {
        const res = await getList("list/capsules");
        setLoading(false)
        setCapsules(res);
    };

    const handleInputChangeCapsule = e => {
        setCapsule({
            ...capsule,
            [e.target.name]: e.target.value
        });
    };

    /*MODAL#####################################################################################*/      
    const [displayModalCapsule, setDisplayModalCapsule] = useState(false);
    const showModalCapsule = capsule => {
        setCapsule(capsule);
        setDisplayModalCapsule(!displayModalCapsule);
    };

    /*SIMPLEMODAL###############################################################################*/ 
    const modalRef = useRef()
    const setModal = (options) => {
        modalRef.current.setModal(options);
    };

    /*CRUD######################################################################################*/ 
    const sendCapsule = async e => {
        e.preventDefault();
        try {
            const res = await axios.put("person/capsule/", capsule);
            switch (res.data.result[0][0].cod) {
                case 0:
                    fetchCapsules();
                    setDisplayModalCapsule(!displayModalCapsule);
                    break;
                case 1:
                    setModal({title: 'Cápsulas', text: 'La cápsula ' + capsule.capsule_description + ' ya existe.', type: 1});
                    break;
                case 2:
                    setModal({title: 'Cápsulas', text: 'La cápsula ' + capsule.capsule_description + ' ya existe pero esta inactiva', type: 1});
                    break;
                default:
                    setModal({title: 'Error', text: 'Despierte al administrador de sistemas ' + res.data.result[0][0].msg, type: 1});
                    break;
            };
        } catch (e) {
            console.log('Err: ' + e);
        };
    };

    const updateStateCapsule = async (capsule_id) => {
        await axios.put("person/capsule/" + capsule_id);
        setModal({});
        fetchCapsules();
    };

    const reqTag = <span style={{color: "#DC3545"}}>*</span>

    return (
        <Container className="mt-3">
            <SimpleModal ref={modalRef}/>
            <Card>
                <CardBody>
                    <h1>Configuración de cápsulas educativas App</h1>
                    <Input type="text" placeholder="Buscar..." autoComplete="off" name="look_for" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}></Input>
                    <br/>
                    {loading 
                        ?<div className="w-100 d-flex justify-content-center">
                            <div className="loader"></div>
                        </div>
                        :<>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr className="text-center">
                                        <th scope="col">Descripcion</th>
                                        <th scope="col">URL</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {capsules.filter(e => {
                                        if(searchTerm == "") {
                                            return e;
                                        } else if (e.capsule_description.toLowerCase().includes(searchTerm.toLowerCase())) {
                                            return e;
                                        }
                                    }).map(e => (
                                        <tr className="text-center" key={e.capsule_id}>
                                            <td>{e.capsule_description}</td>
                                            <td><a rel="noopener noreferrer" target="_blank" href={e.capsule_url}>{e.capsule_url}</a></td>
                                            <td>
                                                <Button onClick={() => showModalCapsule(e)} title="Editar" className="mr-2" color="primary" size="sm" outline><i className="far fa-edit"/></Button>
                                            </td>
                                            <td>
                                                <Button onClick={() => setModal({title: 'Eliminando cápsula', text: 'Desea eliminar cápsula ' + e.capsule_description + '?', type: 2, fx: () => updateStateCapsule(e.capsule_id)})} title="Eliminar" className="mr-2" color="danger" size="sm" outline><i className="far fa-trash-alt"/></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className= "float-right mb-1 mr-4 mt-3">
                                <Button color="primary" onClick={() => showModalCapsule(defaultCapsule)}>Nueva</Button>
                            </div>
                        </>
                    }   
                </CardBody>
            </Card>
            <Modal isOpen={displayModalCapsule}>
                <ModalHeader toggle={() => {setDisplayModalCapsule(!displayModalCapsule)}}>
                    {capsule.capsule_id == 0 ? 'Nueva cápsula' : 'Editando cápsula'}
                </ModalHeader>
                <ModalBody className= "pt-1">
                    <Form role="form" onSubmit={e => sendCapsule(e)}>
                        <h5 style={{color: "#DC3545"}}>* Existen campos obligatorios</h5>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Descripción {reqTag}</Label>
                                <Input 
                                    required autoComplete="off" maxLength="130" type="text" 
                                    name="capsule_description"
                                    value={capsule.capsule_description}
                                    onChange={e => handleInputChangeCapsule(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Link {reqTag}</Label>
                                <Input 
                                    required autoComplete="off" maxLength="500" type="text"
                                    name="capsule_url"
                                    value={capsule.capsule_url}
                                    onChange={e => handleInputChangeCapsule(e)}
                                />
                            </Col>
                        </FormGroup>
                        <div className= "float-right mb-2 mr-2">
                            <Button type="submit" color="primary">Guardar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default ConfCapsules;