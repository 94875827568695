import React, { useState, useEffect, Suspense } from 'react'
import Axios from '../../../helpers/axiosConfig'
import { Button, ButtonGroup, ButtonToolbar, Tooltip } from 'reactstrap'
import { Canvas } from "react-three-fiber";
import AddMarkerForm from '../../../components/Fantoma/FormsExams/AddMarkerForm'
import EditMarkerForm from '../../../components/Fantoma/FormsExams/EditMarkerForm'
import MarkersGroup from '../../../components/Fantoma/Tag/MarkersGroup'
import CameraControls from '../../../components/Fantoma/CameraControls'
import MouseHelper  from '../../../components/Fantoma/MouseHelper'
import FantomaIntraOral  from '../../../components/Fantoma/FantomaIntraOral'
import { Link } from 'react-router-dom';


import { getList } from 'helpers/ListHelper';



const SoftTissuesExam = ({location, update, updateSize}) => {
    const typeExam="soft-tissues-exam"
    const [positionHelper, setPositionHelper]= useState()
    const [orientationHelper, setOrientationHelper]= useState()

    const [canvasSize, setCanvasSize] = useState({height: undefined}); 
    const [rSelected, setRSelected] = useState(null);

    const [visibilityHelper, setVisibilityHelper] = useState(false) 
    
    const initialFormState = { 
        id: null, 
        idAnamnesis: null,
        form: null,
        length: null,
        width: null,
        height: null,
        color: null,
        surface: null,
        consistency: null,
        evolution_time: null,
        evolution_form: null,
        symptomatology: null,
        edge: null,
        localization: null,
        observation: '',
        position_x: null,
        position_y: null,
        position_z: null,
        orientation_x: null,
        orientation_y: null,
        orientation_z: null, 
        injury: 1       
    }

    const getPositionHelper = (positionHelper) => {
        setPositionHelper(positionHelper)
    }
    const getOrientationHelper = (orientationHelper) => {
        setOrientationHelper(orientationHelper)
    }
  
    const [markers, setMarkers] = useState([])
    const [currentMarker, setCurrentMarker ] = useState(initialFormState)
    
    const [adding, setAdding ] = useState(false)
    const [editing, setEditing ] = useState(false)

    const [addTooltipOpen, setAddTooltipOpen] = useState(false);
    const [editTooltipOpen, setEditTooltipOpen] = useState(false);

    const addToggle = () => setAddTooltipOpen(!addTooltipOpen);
    const editToggle = () => setEditTooltipOpen(!editTooltipOpen);


    const getMarkers = async () => {
        const newMarkers = [] 
        await Axios.get("soft-tissues-exam/" + location.state.anamId)
        
        .then(response => {
            setMarkers([])
            response.data.result.forEach(element => {
                newMarkers.push({
                    id: element.soft_tissues_exam_id,
                    idAnamnesis: location.state.anamId, 
                    form: element.form_id,     
                    length:element.length, 
                    width:element.width, 
                    height:element.height, 
                    color:element.color_id, 
                    surface:element.surface_id, 
                    consistency:element.consistency_id, 
                    evolution_time:element.evolution_time,
                    evolution_form:element.evolution_form_id, 
                    symptomatology:element.symptomatology_id, 
                    edge: element.edge_id,
                    localization:element.localization_id, 
                    observation:element.observation, 
                    position_x:element.position_x, 
                    position_y:element.position_y, 
                    position_z:element.position_z,
                    orientation_x:element.orientation_x, 
                    orientation_y:element.orientation_y, 
                    orientation_z:element.orientation_z, 
                    injury: element.injury_id,
                    foto: element.foto  
                });
                setMarkers([...newMarkers])   
            });
        })  
    }


    const [image, setImage] = useState([])


    const [formData, setFormData] = useState([]);
    const [colorData, setColorData] = useState([]);
    const [surfaceData, setSurfaceData] = useState([]);
    const [consistencyData, setConsistencyData] = useState([]);
    const [evolutionFormData, setEvolutionFormData] = useState([]);
    const [symptomatologyData, setSymptomatologyData] = useState([]);
    const [edgeData, setEdgeData] = useState([]);
    const [localizationData, setLocalizationData] = useState([]);
    const [injuryData, setInjuryData] = useState([]);

    const getData = async (set, route) => {
        const e = await getList(route)
        set(e)
    }


    const fetchImages= async () => {
        const res = await Axios.get("extra-oral-exam/photo/"+ location.state.anamId)
        console.log(res.data)
        if (!res.data.error) {
            setImage(res.data.result);
        };       
    };

    const helperAddMarker = ()=>{
        if(rSelected===1){
            setAdding(true)
        }
    }


    const addMarker = async (marker) => {
        if(rSelected===1){
            setAdding(true)
            const res = await Axios.post("soft-tissues-exam",
                {
                    dentalAnamnesisId : location.state.anamId,
                    formId : marker.form ,
                    length: marker.length,
                    width: marker.width,
                    height: marker.height,
                    colorId : marker.color,
                    surfaceId : marker.surface,
                    consistencyId : marker.consistency,
                    evolutionTime : marker.evolution_time,
                    evolutionFromId : marker.evolution_form,
                    symptomatologyId: marker.symptomatology,
                    edgeId: marker.edge,
                    localizationId: marker.localization,
                    observation: marker.observation,
                    position_x: positionHelper.x,
                    position_y: positionHelper.y,
                    position_z: positionHelper.z,
                    orientation_x: orientationHelper.x,
                    orientation_y: orientationHelper.y,
                    orientation_z: orientationHelper.z,
                    injury: marker.injury,
                    foto: marker.foto
                }
		    )
            if (!res.data.error){
                //alert("Etiqueta guardada")
                setMarkers([ ...markers, marker ])
                getMarkers()
            }
        }
        setAdding(false)
	}

    const deleteMarker = async (id) => {

        setEditing(false)
        const res = await Axios.delete("soft-tissues-exam/" + id);
        if (!res.data.error) {
            setMarkers(markers.filter(marker => marker.id !== id))
            getMarkers()
            //alert("Etiqueta eliminada")
        }
    }
    
    const updateMarker = async (id, updatedMarker) => {
        setEditing(false)
        const res = await Axios.put("soft-tissues-exam",
            {
                dentalAnamnesisId : location.state.anamId,
                formId : updatedMarker.form ,
                length: updatedMarker.length,
                width: updatedMarker.width,
                height: updatedMarker.height,
                colorId : updatedMarker.color,
                surfaceId : updatedMarker.surface,
                consistencyId : updatedMarker.consistency,
                evolutionTime : updatedMarker.evolution_time,
                evolutionFromId : updatedMarker.evolution_form,
                symptomatologyId: updatedMarker.symptomatology,
                edgeId: updatedMarker.edge,
                localizationId: updatedMarker.localization,
                observation: updatedMarker.observation,
                position_x: updatedMarker.position_x,
                position_y: updatedMarker.position_y,
                position_z: updatedMarker.position_z,
                orientation_x:updatedMarker.orientation_x, 
                orientation_y:updatedMarker.orientation_y, 
                orientation_z:updatedMarker.orientation_z,
                injury: updatedMarker.injury,
                foto: updatedMarker.foto,
                softTissuesExamId: id
            }
        )
        if (!res.data.error){
		    setMarkers(markers.map(marker => (marker.id === id ? updatedMarker : marker)))
            getMarkers()
            //alert("Etiqueta actualizada")
        }
	}

    const editMarker = marker => {
		setEditing(true)
        setCurrentMarker({ 
            id: marker.id, 
            idAnamnesis: marker.idAnamnesis,
            form: marker.form,
            length: marker.length,
            width: marker.width,
            height: marker.height,
            color: marker.color,
            surface: marker.surface,
            consistency: marker.consistency,
            evolution_time: marker.evolution_time,
            evolution_form: marker.evolution_form,
            symptomatology: marker.symptomatology,
            edge: marker.edge,
            localization: marker.localization,
            observation: marker.observation,
            position_x: marker.position_x,
            position_y: marker.position_y,
            position_z: marker.position_z,
            orientation_x: marker.orientation_x,
            orientation_y: marker.orientation_y,
            orientation_z: marker.orientation_z,
            injury: marker.injury,
            foto: marker.foto
        })
    }


    useEffect(() => {
		const navbarSize = document.getElementsByClassName('navbar-top')[0];
		const footerSize = document.getElementsByClassName('footer')[0];
		const handleResize = () => {
			setCanvasSize({
				width: window.innerWidth -(update ==! undefined  ? (230):(0)) - navbarSize.offsetWidth - footerSize.offsetWidth,
				height: window.innerHeight-(update ==! undefined  ? (230):(0))  - navbarSize.offsetHeight - footerSize.offsetHeight
			});
		}
		window.addEventListener("resize", handleResize);
        handleResize();
        
        getMarkers()
        fetchImages()
        getData(setFormData, "list/forms")
        getData(setColorData, "list/colors")
        getData(setSurfaceData, "list/surfaces")
        getData(setConsistencyData, "list/consistencies")
        getData(setEvolutionFormData, "list/evolution-forms")
        getData(setSymptomatologyData, "list/symptomatologies")
        getData(setEdgeData, "list/edges")
        getData(setLocalizationData, "list/localizations")
        getData(setInjuryData, "list/injuries")

        return () => window.removeEventListener("resize", handleResize); 
	}, []);
    

    return (
        <>
        <ButtonToolbar style={{ zIndex: 100, position: 'absolute', top:'90%', left:'50%', transform:'translate(-50%, -50%)' }}>
            <ButtonGroup>
				<Button color="primary" size="lg" onClick={() => setRSelected(1)} active={rSelected === 1} id="add">
                    <i className="fas fa-tags"></i>
                </Button>
                    <Tooltip
                        placement="top"
                        isOpen={addTooltipOpen}
                        target={"add"}
                        toggle={addToggle}
                    >
                        Añadir marcador
                    </Tooltip>
				<Button color="primary" size="lg" onClick={() => setRSelected(2)} active={rSelected === 2} id="edit">
                    <i className="ni ni-active-40"></i>
                </Button>
                    <Tooltip
                        placement="top"
                        isOpen={editTooltipOpen}
                        target={"edit"}
                        toggle={editToggle}
                    >
                        Abrir marcador
                    </Tooltip>
			</ButtonGroup>

        </ButtonToolbar>
        {!update
            ? 
            <ButtonGroup style={{ zIndex: 100, position: 'absolute', top:'90%', left:'95%', transform:'translate(-100%, -50%)' }}>
                <Button color="primary" size="lg"><Link style={{color: "white"}} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido,
                        anamId: location?.state?.anamId
                    },
                    pathname: "/admin/extra-oral"
                    }}>Extra Oral</Link>
                </Button>
                <Button color="primary" active size="lg">Tejidos Blandos</Button>
                <Button color="primary" size="lg"><Link style={{color: "white"}} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido,
                        anamId: location?.state?.anamId
                    },
                    pathname: "/admin/odontogram"
                    }}>Odontograma</Link>
                </Button>
                <Button color="info" size="lg"><Link style={{color: "white"}} to={{
                    state: {
                        id: location?.state?.id,
                        run: location?.state?.run,
                        name: location?.state?.name,
                        apellido: location?.state?.apellido
                    },
                    pathname: "/admin/periodontogram"
                    }}>Periodontograma</Link>
                </Button>
            </ButtonGroup>
            : <></>
        }
            {editing ? (
                <EditMarkerForm
                    editing={editing}
                    setEditing={setEditing}
                    currentMarker={currentMarker}
                    updateMarker={updateMarker}
                    image={image}
                    fetchImages={fetchImages}
                    formData={formData}
                    colorData={colorData}
                    surfaceData={surfaceData}
                    consistencyData={consistencyData}
                    evolutionFormData={evolutionFormData}
                    symptomatologyData={symptomatologyData}
                    edgeData={edgeData}
                    localizationData={localizationData}
                    injuryData={injuryData}
                />
            ) : (
                <AddMarkerForm
                    adding={adding}
                    setAdding={setAdding} 
                    addMarker={addMarker}
                    image={image}
                    fetchImages={fetchImages}
                    formData={formData}
                    colorData={colorData}
                    surfaceData={surfaceData}
                    consistencyData={consistencyData}
                    evolutionFormData={evolutionFormData}
                    symptomatologyData={symptomatologyData}
                    edgeData={edgeData}
                    localizationData={localizationData}
                    injuryData={injuryData} 
                />
            )}
            <Canvas className="canvas"
				gl={{ antialias: true }}
				shadowMap
				pixelRatio={window.devicePixelRatio}
				style={{ height: canvasSize.height, cursor: visibilityHelper ? ('crosshair'):('default') }}
				camera={{ fov: 55, position: [0, 0, 10] }}
			>
				<CameraControls minDistance={0.5} maxDistance={1.5}/>
				<spotLight intensity={0.4} position={[300, 300, 2000]} castShadow={true} />
				<spotLight intensity={0.35} position={[-3000, 1300, -1000]} castShadow={true} />
				<ambientLight intensity={0.07} />
				<Suspense fallback="loading">
                {rSelected === 1 ? (<MouseHelper visibility={true} handlePosition={getPositionHelper}  handleOrientation={getOrientationHelper} typeExam={typeExam}/>):(<></>)}
					{/* <MouseHelper visibility={visibilityHelper} handlePosition={getPositionHelper}/> */}
					<FantomaIntraOral scale={[10, 10, 10]} position={[0, 0, 0]} onDoubleClick={helperAddMarker} onPointerOver={() => setVisibilityHelper(true)} onPointerOut={() => setVisibilityHelper(false)} />
                    <MarkersGroup 
                        markers={markers} 
                        editMarker={editMarker} 
                        deleteMarker={deleteMarker} 
                        orientationHelper={orientationHelper} 
                        positionHelper={positionHelper} 
                        adding={rSelected} 
                        formData={formData} 
                        colorData={colorData} 
                        surfaceData={surfaceData} 
                        consistencyData={consistencyData} 
                        evolutionFormData={evolutionFormData} 
                        symptomatologyData={symptomatologyData} 
                        edgeData={edgeData} 
                        localizationData={localizationData}
                        injuryData={injuryData}
                        image={image}	
                     />
                    
				</Suspense>
				{/* <Stats /> */}
			</Canvas>
        </>
    )
}
export default SoftTissuesExam